.cehm-error {
    color: #c40f33;
    font-size: 1.25em;
}
.cehm-error-sm {
    color: #c40f33;
    font-size: .9em;
    padding: 4px;
}

.data-grid-true {
    background-color: #c1dec9;
}

.data-grid-false {
    background-color: #dec1c1;
}